/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

*, *:before, *:after {
  box-sizing: border-box;
}

html {
  font-size: 40px;
  height: 100%;
}

@media (min-width: 768px) {
  html {
    font-size: 60px;
  }
}

body {
  font-family: Georgia, Times, Times New Roman, serif;
  background: #3C3C3C url("../images/pattern_dankeskarte.png") center center repeat;
  margin: 0;
  font-size: 1rem;
  line-height: 1.203125;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.clearfix::before, .clearfix::after {
  content: '';
  display: table;
}

.clearfix::after {
  clear: both;
}

h1 {
  color: #fff;
  font-size: 1rem;
  font-weight: normal;
  position: relative;
  margin: 0;
}

.wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.8s;
  overflow: hidden;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 80%;
  max-width: 580px;
}

.text {
  margin-right: 20%;
}

.play {
  background: url(../images/play.png) center center no-repeat;
  background-size: contain;
  width: 18%;
  cursor: pointer;
  position: absolute;
  margin-top: 10%;
}

.play:before {
  content: '';
  display: block;
  padding-top: 71%;
}

video {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  transform: translate(-100%, 0);
}

#c1 {
  display: none;
}

#c2 {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate3d(-50%, 0, 0) scale(1);
  transform-origin: center top;
  z-index: 2;
  cursor: pointer;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

.box {
  position: absolute;
  top: 12%;
  left: 7%;
  max-width: 580px;
  width: 40%;
  min-width: 240px;
  z-index: 1;
  pointer-events: none;
  transform: rotate(-5deg);
}

.box:before {
  content: '';
  display: block;
  padding-top: 78.747628083%;
}

.box:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: url(../images/rahmen.png) center center no-repeat;
  background-size: contain;
}

.box .star {
  position: absolute;
  left: 0;
  top: 0;
  width: 80px;
  height: 80px;
  background: url(../images/sparkle.svg) center center no-repeat;
  background-size: contain;
  z-index: 2;
  margin-top: -35px;
  margin-left: -35px;
  animation: sparkle 10s linear infinite;
}

.box .images {
  position: absolute;
  left: 5%;
  top: 5%;
  width: 90%;
  height: 90%;
  overflow: hidden;
}

.box .slick-list, .box .slick-track {
  height: 100%;
}

.box .slick-slide {
  background: center center no-repeat;
  background-size: cover;
}

.hide {
  opacity: 0;
  z-index: -1;
}

.legacy {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 10%;
  background: #3C3C3C;
}

.legacy p {
  color: #fff;
  font-size: 0.7rem;
  font-weight: normal;
  position: relative;
  margin: 0 0 0.8em;
}

.legacy:not(.complete) p:last-child {
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 0;
}

.logo {
  position: absolute;
  bottom: 10px;
  right: 15px;
  background: url(../images/logo_weiss.svg) center center no-repeat;
  background-size: contain;
  width: 120px;
  opacity: 0.8;
}

.logo:before {
  content: '';
  display: block;
  padding-top: 32.8125%;
}

@keyframes sparkle {
  0% {
    transform: rotate(0deg) scale(0.8);
  }
  30% {
    transform: rotate(108deg) scale(1.2);
  }
  100% {
    transform: rotate(360deg) scale(0.8);
  }
}
